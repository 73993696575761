import { graphql, useStaticQuery } from "gatsby"

const useTags = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        tags(first: 100) {
          nodes {
            name
            tagId
          }
        }
      }
    }
  `)

  return data.wordPress.tags.nodes
}

export default useTags
