import React, { useState } from "react"
import styled from "@emotion/styled"
import Collapsible from "react-collapsible"

const TriggerWrapper = styled.div`
  padding: 12px 10px;

  cursor: pointer;
  position: relative;
  :after {
    position: absolute;
    right: 16px;
    top: 12px;
    transform: ${props => (props.isOptionsOpen ? "rotate(180deg)" : "none")};
    transition-duration: 0.3s;
    transition-property: transform;
    content: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.881836 0.882355L6.67131 5.88235L11.8818 0.882355' stroke='%23909090' stroke-width='2'/%3E%3C/svg%3E%0A");
  }
`
const AdjusterWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`

const ApartmentsFilter = props => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  return (
    <Collapsible
      transitionTime={200}
      onTriggerOpening={() => {
        setIsOptionsOpen(true)
      }}
      onTriggerClosing={() => {
        setIsOptionsOpen(false)
      }}
      trigger={
        <TriggerWrapper isOptionsOpen={isOptionsOpen}>
          {props.triggerName}
        </TriggerWrapper>
      }
    >
      <AdjusterWrapper>{props.children}</AdjusterWrapper>
    </Collapsible>
  )
}

export default ApartmentsFilter
