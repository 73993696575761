import React from "react"
import styled from "@emotion/styled"
import PopUpContainer from "./popupcontainer"

const FloorInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

const FloorInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 0.9rem;
  font-weight: 500;

  img {
    height: auto;
    min-height: 500px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.03);
    margin-top: 15px;
  }
  ${props => props.theme.tablet} {
    img {
      min-height: 5px;
    }
  }
`

const NoImage = styled.div`
  font-size: 1rem;
  margin: 0.5rem 0 1.5rem 0;
`

const InfoFloor = ({ toggleFloorInfo, selectedFloor }) => {
  const floorsInfo = () => {
    return selectedFloor.map(floorInfo => {
      if (floorInfo.filler) {
        return (
          <FloorInfo key={floorInfo.entrance + floorInfo.floor}>
            {floorInfo.entrance && (
              <span>Cекция / подъезд № {floorInfo.entrance}</span>
            )}
            <span>Этаж {floorInfo.floor}</span>
            <NoImage>Схемы нет</NoImage>
          </FloorInfo>
        )
      }
      return (
        <FloorInfo key={floorInfo.entrance + floorInfo.floor}>
          {floorInfo.entrance && (
            <span>Cекция / подъезд № {floorInfo.entrance}</span>
          )}
          <span>Этаж {floorInfo.floor}</span>
          {floorInfo.image ? (
            <img src={floorInfo.image} alt="alternatetext" />
          ) : (
            <NoImage>Схемы нет</NoImage>
          )}
        </FloorInfo>
      )
    })
  }

  return (
    <PopUpContainer changeInfoShow={toggleFloorInfo}>
      <FloorInfoContainer>{floorsInfo()}</FloorInfoContainer>
    </PopUpContainer>
  )
}

export default InfoFloor
