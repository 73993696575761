import React, { useState } from "react"
import styled from "@emotion/styled"
import {
  parseApartmentsByEntrace,
  parseApartmentsByFloor,
} from "../../../../helpers"

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: ${props => {
    if (props.hoveredRow) return "rgba(0, 0, 0, 0.05)"
    return "transparent"
  }};
`

const FloorNumberContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  min-width: 150px;
  height: 24px;
  margin: 5px;
  color: #123963;
  cursor: pointer;
`

const FloorNumber = styled.span`
  opacity: ${props => (props.showFloor ? "1" : "0")};
  display: inline-block;
  margin: 5px;
`

const RowChess = props => {
  const [showFloor, setIsShowFloor] = useState(false)

  const showFloorInfo = floor => {
    const currentFloor = parseApartmentsByFloor(floor)?.[props.floorNumber]
    if (currentFloor) {
      const InformationAboutFloor = parseApartmentsByEntrace(currentFloor)
      const floorImage = InformationAboutFloor.map(apartmentsOnFloor => {
        return {
          image: apartmentsOnFloor[0]["floor_image"],
          entrance: apartmentsOnFloor[0]["entrance"],
          floor: apartmentsOnFloor[0]["floor"] || props.floorNumber,
          filler: apartmentsOnFloor[0]["filler"],
        }
      })
      props.showFloorInfo(floorImage)
    } else {
      const floorImage = [
        {
          floor: props.floorNumber,
          filler: "filler",
        },
      ]
      props.showFloorInfo(floorImage)
    }
  }

  return (
    <Row
      onMouseEnter={() => {
        setIsShowFloor(true)
        props.onMouseEnter()
      }}
      onMouseLeave={() => {
        setIsShowFloor(false)
        props.onMouseLeave()
      }}
      hoveredRow={props.hoveredRow}
    >
      {props.children}
      <FloorNumberContainer
        onClick={() => {
          showFloorInfo(props.floor)
        }}
      >
        <FloorNumber showFloor={showFloor}>План</FloorNumber>
        <span>{props.floorNumber}</span>
        <FloorNumber showFloor={showFloor}>этажа</FloorNumber>
      </FloorNumberContainer>
    </Row>
  )
}

export default RowChess
