import React from "react"
import styled from "@emotion/styled"

const StatusBadge = styled.span`
  display: inline-block;
  margin-right: 5px;
  color: white;
  height: 22px;
  width: 22px;
  text-align: center;
  padding: 5px;
  line-height: 1;
  background-color: ${props => {
    if (props.status === "реализовано") return "rgba(144, 144, 144, 1)"
    if (props.status === "свободно") return "rgba(99, 203, 165, 1)"
  }};
`

const StatusBadgeComponent = props => {
  return <StatusBadge status={props.status}>{props.children}</StatusBadge>
}

export default StatusBadgeComponent
