import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import ListIcon from "../../../assets/list.svg"
import { addQueryParam, scrollToTop } from "../../../helpers"
import ChessIcon from "../../../assets/chess.svg"

const LayoutButtonsWrapper = styled.div`
  display: flex;
`
const LayoutButton = styled.button`
  z-index: 5;
  cursor: ${props => (props.inactive ? "auto" : "pointer")};
  background: #ffffff;
  opacity: ${props => (props.inactive ? "0.5" : "1")};
  color: ${props => (props.active ? "#123963" : "black")};
  border: ${props =>
    props.active ? "2px solid #123963;" : "1px solid  #e5e5e5;"};
  border-radius: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: ${props => (props.active ? "9px 13px;" : "10px 14px;")};
  margin: 0px 10px 13px 0;
  svg {
    stroke: ${props => (props.active ? "#123963" : "#909090")};
    fill: ${props => (props.active ? "#123963" : "#909090")};
  }
`

const ButtonInnerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 105px;
`

const ToolTip = styled.div`
  bottom: -2.4rem;
  left: -0.5rem;
  position: absolute;
  width: 120px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  padding: 5px 0;
  font-size: 0.7rem;

  &:after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`

const ChangeLayoutButtons = ({
  setLayout,
  layout,
  changeInfoShow,
  chessViewDisabled,
  clearScrollValue,
  closeFloorInfo,
}) => {
  const [mouseOver, setMouseOver] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)

  useEffect(() => {
    const toogleToolTip = status => {
      if (chessViewDisabled) {
        setShowToolTip(status)
        return
      }
      return
    }
    if (mouseOver === false) {
      toogleToolTip(mouseOver)
      return
    }
    const delayDebounceFn = setTimeout(() => {
      toogleToolTip(mouseOver)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [mouseOver])

  const changeLayoutHandler = layout => {
    clearScrollValue()
    changeInfoShow(false)
    scrollToTop()
    addQueryParam("layout", layout)
    setLayout(layout)
    closeFloorInfo(false)
  }

  return (
    <LayoutButtonsWrapper>
      <LayoutButton
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        inactive={chessViewDisabled}
        active={layout === "chess" ? "active" : null}
        onClick={() => {
          if (chessViewDisabled) return
          changeLayoutHandler("chess")
        }}
      >
        <ButtonInnerWrapper>
          <ChessIcon />
          <span>Шахматка</span>
          {showToolTip ? <ToolTip>Выберите дом</ToolTip> : null}
        </ButtonInnerWrapper>
      </LayoutButton>
      <LayoutButton
        active={layout === "table" ? "active" : null}
        onClick={() => changeLayoutHandler("table")}
      >
        <ButtonInnerWrapper>
          <ListIcon />
          <span>Список</span>
        </ButtonInnerWrapper>
      </LayoutButton>
    </LayoutButtonsWrapper>
  )
}

export default ChangeLayoutButtons
