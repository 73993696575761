import React, { useState, useEffect } from "react"
import {
  addQueryParam,
  deleteFromQueryParam,
  stateFromQueryParam,
  scrollToTop,
  replaceToComa,
} from "../../../helpers"
import styled from "@emotion/styled"
import TableLayoutButtons from "../dataLayout/tableLayoutButtons"
import Table from "./table"
import Chess from "./chess"
import TableApartmentsSelection from "../dataLayout/apartmentSelection"
import Info from "./info"
import InfoFloor from "./info/floorInfo"
import Filters from "../filters"
import { applyFilters } from "../filters/filtering"
import { parseApartmentsByOnlyFloor } from "../../../helpers"
import UAParser from "ua-parser-js"

const ua = new UAParser()
const deviceTypes = ["mobile", "tablet", "smarttv", "wearable", "embedded"]

const TableApartmentsWrapper = styled.div`
  flex-shrink: 1;
  width: 75%;
  padding-left: 15px;
  ${props => props.theme.tablet} {
    padding-left: 0;
    width: 100%;
  }
`
const TableApartmentsHeader = styled.div`
  h1 {
    font-family: Rubik;
    font-size: 27px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    padding-bottom: 0;
  }

  text-align: left;
  margin-bottom: 18px;
`

const LeftColumnWrapper = styled.div`
  width: 25%;
  flex-shrink: 0;
  > div {
    margin-bottom: 30px;
  }
  ${props => props.theme.tablet} {
    width: 100%;
  }
`

const Information = styled.div`
  margin-right: 20px;
  font-size: 0.9rem;
  a {
    font-size: 0.7rem;
    ${props => props.theme.tablet} {
      font-size: 0.8rem;
    }
  }
  p {
    margin-bottom: 10px;
    line-height: 1;
  }
  ${props => props.theme.tablet} {
    margin-right: 0;
    font-size: 1rem;
    margin-top: 15px;
  }
`

const WrapperApartments = styled.div`
  display: flex;
  ${props => props.theme.tablet} {
    flex-direction: column;
  }
`

const LoadingPlaceHolder = styled.div`
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.05);
`

const fieldsOfApartmentsToParse = {
  house_number: "Номер дома",
  entrance: "Секция / подъезд",
  site_number: "Номер на площадке",
  room_number: "Кол-во комнат",
  floor: "Этаж",
  total_area_balcony: "Площадь, м2",
  price_square: "Стоимость 1м2, руб",
  price_total: "Стоимость объекта, руб",
}

const buildQuery = (page, tag) => {
  return `https://fond214pk.ru/wp-json/wp/v2/apartments?_fields=acf&per_page=500&page=${page}&tags=${tag}`
}

function parseNumber(number) {
  return parseFloat(replaceToComa(number).replaceAll(" ", ""))
}

const parseCustomFields = data => {
  const filtersValues = {
    room_type: new Set(),
    room_number: new Set(),
    status: new Set(),
    floor: { min: 1, max: 0 },
    area: { min: 0, max: 0 },
  }
  const apartments = data.map(({ acf }) => {
    filtersValues.room_type.add(acf.room_type)
    filtersValues.status.add(acf.status)

    if (!isNaN(parseInt(acf.room_number))) {
      filtersValues.room_number.add(parseInt(acf.room_number))
    }
    if (parseInt(acf.floor) < filtersValues.floor.min) {
      filtersValues.floor.min = parseInt(acf.floor)
    }
    if (parseInt(acf.floor) > filtersValues.floor.max) {
      filtersValues.floor.max = parseInt(acf.floor)
    }
    if (parseFloat(acf.total_area_balcony) > filtersValues.area.max) {
      filtersValues.area.max = parseNumber(acf.total_area_balcony)
    }

    const parsedNumberFields = Object.keys(fieldsOfApartmentsToParse).reduce(
      (accurmulator, current) => {
        if (typeof acf[current] !== "string") {
          return {
            ...accurmulator,
            [current]: acf[current],
          }
        }

        const field = parseNumber(acf[current])

        if (isNaN(field)) {
          return {
            ...accurmulator,
            [current]: acf[current],
          }
        }

        if (current === "site_number") {
          return {
            ...accurmulator,
            [current]: field,
            site_number_with_index: acf[current],
          }
        }

        return {
          ...accurmulator,
          [current]: field,
        }
      },
      {}
    )
    return { ...acf, ...parsedNumberFields }
  })
  return {
    apartments: apartments,
    filters: filtersValues,
  }
}

const defaultFilters = [
  {
    name: "Свободно",
    group: "status",
    fnc: apartment => {
      if (typeof apartment.status === "string")
        return apartment.status.toLowerCase() === `Свободно`.toLowerCase()
    },
  },
]

const DataLayout = ({ tags, salesPage }) => {
  const [selectedTag, selectTag] = useState(() => {
    return stateFromQueryParam(
      "tag",
      tags.find(tag => tag.name.toLowerCase() === "свободно")?.tagId
    )
  })
  const [loaded, changeLoadingStatus] = useState(false)
  const [layout, setLayout] = useState()
  const [apartments, setApartments] = useState([])
  const [filteredApartments, setfilteredApartments] = useState([])
  const [infoOpen, changeInfo] = useState(false)
  const [infoFloorOpen, changeInfoFloor] = useState(false)
  const [selectedApartment, selectApartment] = useState({})
  const [selectedFloor, selectFloor] = useState([])
  const [scrollValue, setScrollValue] = useState(0)
  const [filters, setFilters] = useState(defaultFilters)
  const [filtersValues, setFiltersValues] = useState({
    room_type: new Set(),
    room_number: new Set(),
    status: new Set(),
    floor: { min: 1, max: 0 },
    area: { min: 0, max: 0 },
  })
  const [chessViewDisabled, disableChessView] = useState(false)
  const [currentDeviceType, setMobile] = useState("unknow")

  useEffect(() => {
    const currentDevice = ua.getDevice()
    if (deviceTypes.includes(currentDevice.type)) {
      setMobile("mobile")
    } else {
      setMobile("desktop")
    }
  }, [])

  const clearFilters = () => {
    setFilters(defaultFilters)
  }

  const clearScrollValue = () => {
    setScrollValue(0)
  }

  useEffect(() => {
    if (
      tags.find(tag => tag.tagId == selectedTag)?.name.toLowerCase() ===
      "свободно"
    ) {
      disableChessView(true)
      setLayout("table")
      addQueryParam("layout", "table")
    } else {
      disableChessView(false)
    }
    fetchApartments(selectedTag)
  }, [selectedTag, tags])

  useEffect(() => {
    setfilteredApartments(applyFilters(apartments, filters))
  }, [filters, apartments])

  useEffect(() => {
    if (typeof layout !== "string")
      setLayout(stateFromQueryParam("layout", "table"))
    if (
      typeof stateFromQueryParam("open") === "string" &&
      apartments.length > 0
    ) {
      changeInfo(true)
      const [apartment] = apartments.filter(apartment => {
        return apartment.site_number === parseInt(stateFromQueryParam("open"))
      })
      selectApartment(apartment)
    }
    if (
      typeof stateFromQueryParam("floor_info") === "string" &&
      apartments.length > 0
    ) {
      changeInfoFloor(true)
      const floorNumber = stateFromQueryParam("floor_info")
      const apartmentsByFloor = apartments.filter(apartment => {
        return apartment.floor === parseInt(stateFromQueryParam("floor_info"))
      })

      const parsedApartmentsByFloor = parseApartmentsByOnlyFloor(
        apartmentsByFloor
      )[floorNumber]

      if (!parsedApartmentsByFloor) {
        return
      }
      const selectedFloorInfo = Object.values(parsedApartmentsByFloor)
      const floorInfo = Object.keys(selectedFloorInfo).map(index => {
        const apartment = selectedFloorInfo[index][0]
        return {
          image: apartment["floor_image"],
          entrance: apartment["entrance"],
          floor: apartment["floor"],
        }
      })
      selectFloor(floorInfo)
    }
  }, [apartments])

  const closeShowInfo = status => {
    changeInfo(status)
    deleteFromQueryParam("open")
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollValue)
    })
  }

  const showInfo = apartment => {
    selectApartment(apartment)
    addQueryParam("open", apartment.site_number)
    if (typeof window !== `undefined`) {
      setScrollValue(window.scrollY)
    }
    scrollToTop()
    changeInfo(true)
  }

  const showFloorInfo = floorInfo => {
    selectFloor(floorInfo)
    addQueryParam("floor_info", floorInfo[0].floor)
    if (typeof window !== `undefined`) {
      setScrollValue(window.scrollY)
    }
    scrollToTop()
    changeInfoFloor(true)
  }

  const closeFloorInfo = status => {
    changeInfoFloor(status)
    deleteFromQueryParam("floor_info")
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollValue)
    })
  }

  const fetchApartments = selectedTag => {
    let totalPages
    let data

    changeLoadingStatus(false)
    fetch(buildQuery(1, selectedTag))
      .then(response => {
        totalPages = response.headers.get("X-WP-TotalPages")
        return response.json()
      })
      .then(resultData => {
        data = resultData
        const queries = []
        if (totalPages > 1) {
          for (let i = 1; i < totalPages; i++) {
            queries.push(buildQuery(i + 1, selectedTag))
          }
          return Promise.all(queries.map(url => fetch(url)))
        }
        return
      })
      .then(response => {
        if (Array.isArray(response)) {
          return Promise.all(response.map(res => res.json()))
        }
        return
      })
      .then(newData => {
        if (newData) {
          data = [...data, ...newData.flat()]
        }
        if (data.length > 0) {
          const apartmentsAndFilters = parseCustomFields(data)
          setFiltersValues(apartmentsAndFilters.filters)
          setApartments(apartmentsAndFilters.apartments)
        }
        changeLoadingStatus(true)
      })
  }

  const layoutContent = () => {
    if (layout === "table")
      return (
        <Table
          showInfo={showInfo}
          loaded={loaded}
          totalApartments={apartments.length}
          showedApartments={filteredApartments.length}
          amountOfFilters={filters.length}
          selectedTag={selectedTag}
          apartments={filteredApartments}
        />
      )
    if (layout === "chess")
      return (
        <Chess
          showInfo={showInfo}
          showFloorInfo={showFloorInfo}
          loaded={loaded}
          apartments={apartments}
          filteredApartments={filteredApartments}
        />
      )
    return <LoadingPlaceHolder />
  }

  const contentRender = () => {
    if (infoOpen) {
      return (
        <Info
          changeInfoShow={closeShowInfo}
          selectedApartment={selectedApartment}
        />
      )
    }
    if (infoFloorOpen) {
      return (
        <InfoFloor
          toggleFloorInfo={closeFloorInfo}
          selectedFloor={selectedFloor}
        />
      )
    }
    return layoutContent()
  }

  return (
    <WrapperApartments>
      <LeftColumnWrapper>
        <Filters
          filters={filters}
          setFilters={setFilters}
          filtersValues={filtersValues}
          clearFilters={clearFilters}
        />
        {currentDeviceType === "desktop" && (
          <Information
            dangerouslySetInnerHTML={{ __html: salesPage.content }}
          />
        )}
      </LeftColumnWrapper>
      <TableApartmentsWrapper>
        <TableApartmentsHeader>
          <h1>Продажи</h1>
        </TableApartmentsHeader>
        <TableApartmentsSelection
          tags={tags}
          selectedTag={selectedTag}
          selectTag={selectTag}
          changeInfoShow={closeShowInfo}
          clearFilters={clearFilters}
          clearScrollValue={clearScrollValue}
          closeFloorInfo={closeFloorInfo}
        />
        <TableLayoutButtons
          chessViewDisabled={chessViewDisabled}
          changeInfoShow={closeShowInfo}
          layout={layout}
          setLayout={setLayout}
          clearScrollValue={clearScrollValue}
          closeFloorInfo={closeFloorInfo}
        />
        {contentRender()}
      </TableApartmentsWrapper>
      {currentDeviceType === "mobile" && (
        <Information dangerouslySetInnerHTML={{ __html: salesPage.content }} />
      )}
    </WrapperApartments>
  )
}

export default DataLayout
