import React from "react"
import styled from "@emotion/styled"

const CheckBox = styled.input`
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    line-height: 1;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid rgba(0, 0, 0, 0.1);
  }

  // Box hover
  &:hover + label:before {
    background: ${props => props.theme.colors.activeLink};
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: ${props => props.theme.colors.activeLink};
    border: 2px solid transparent;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 10px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
`

const CheckBoxWrapper = styled.div`
  margin: 5px 0;
`

const FilterCheckbox = props => {
  return (
    <CheckBoxWrapper>
      <CheckBox
        type="checkbox"
        id={props.name}
        name={props.name}
        checked={props.active}
        onChange={() => {
          props.onClick()
        }}
      />
      <label htmlFor={props.name}>{props.name}</label>
    </CheckBoxWrapper>
  )
}

export default FilterCheckbox
