import React, { useState, useMemo, useEffect } from "react"
import {
  scrollToTop,
  addQueryParam,
  stateFromQueryParam,
  replaceToComa,
} from "../../../../helpers"
import styled from "@emotion/styled"
import Paginate from "../../../common/paginate"
import SortIcon from "../../../../assets/sort.svg"

const Table = styled.table`
  background-color: white;
  border-spacing: 0px;
  border-collapse: collapse;

  th {
    :first-of-type {
      width: 25%;
    }
    :nth-of-type(4) {
      width: 12%;
    }
    font-family: Rubik;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    padding: 0;
  }
  td {
    ${props => props.theme.tablet} {
      padding: 1rem 1rem;
      width: auto;
    }
    :first-of-type {
      width: 25%;
    }
    :nth-of-type(4) {
      width: 12%;
    }
    :last-of-type {
      padding: 17px 13px 17px 13px;
    }
    padding: 17px 2px 17px 13px;
    font-family: Rubik;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
  }

  tbody {
    tr {
      border-bottom: 1px solid #e5e5e5;
      :hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  thead {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    th {
      :hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
`

const LoadingRows = styled.tr`
  height: 60px;
  :nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
`

const LoadingCell = styled.span`
  display: inline-block;
  width: 100%;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

const Row = styled.tr`
  cursor: pointer;
  background-color: ${props => {
    if (props.status.toLowerCase() === "реализовано")
      return "rgba(0, 0, 0, 0.05)"
  }};
`

const HeaderCell = styled.th`
  background-color: ${props =>
    props.activeSort ? "rgba(0, 0, 0, 0.03)" : "transparent"};
`

const SortButton = styled.button`
  text-align: ${props => props.aligment};
  display: flex;
  font-size: 0.7rem;
  justify-content: end;
  align-items: center;
  flex-wrap: nowrap;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 13px 9px;
  svg {
    .sort-up {
      stroke: ${props => {
        if (props.activeSort === "ascending") return "black"
      }};
    }
    .sort-down {
      stroke: ${props => {
        if (props.activeSort === "descending") return "black"
      }};
    }

    width: 0.7rem;
    height: 0.7rem;
    margin-left: 5px;
  }
  span {
    display: inline-block;
  }
`

const StatusBadge = styled.span`
  display: inline-block;
  margin-right: 5px;
  height: 16px;
  width: 16px;
  background-color: ${props =>
    props.status.toLowerCase() === "свободно"
      ? "#63cba5"
      : "rgba(144,144,144,1)"};
`

const FreeStatusCellWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
`

const PaginateContainer = styled.div`
  margin-top: 2rem;
  ${props => props.theme.tablet} {
    .pagination {
      padding: 0;
      margin-bottom: 1rem;
    }
  }
`

const TableWrapper = styled.div`
  ${props => props.theme.tablet} {
    overflow: scroll;
  }
`

const fieldsOfApartments = {
  address: "Адрес",
  entrance: "Секция, подъезд",
  site_number: "Номер на площадке",
  room_type: "Тип помещения",
  room_number: "Кол-во комнат",
  floor: "Этаж",
  total_area_balcony: "Площадь, м<sup>2</sup>",
  price_square: "Стоимость <br/>1 м<sup>2</sup>",
  price_total: "Стоимость объекта",
}

const fieldsAligment = {
  address: "right",
  entrance: "right",
  site_number: "right",
  room_type: "left",
  room_number: "right",
  floor: "right",
  total_area_balcony: "right",
  status: "left",
  price_square: "right",
  price_total: "right",
}

const PageSize = 20

const TableApartments = ({
  apartments,
  loaded,
  showInfo,
  totalApartments,
  showedApartments,
  selectedTag,
  amountOfFilters,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: "status",
    direction: "descending",
  })
  const [currentPage, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(() => {
    return Math.ceil(showedApartments / PageSize)
  })

  useEffect(() => {
    setTotalPages(Math.ceil(showedApartments / PageSize))
  }, [showedApartments])

  useEffect(() => {
    if (totalPages < currentPage) {
      setPage(1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (stateFromQueryParam("page")) {
      setPage(stateFromQueryParam("page"))
    }
  }, [])

  useEffect(() => {
    addQueryParam("page", currentPage)
  }, [currentPage])

  const sortedApartments = useMemo(() => {
    let sortableItems = [...apartments]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [apartments, sortConfig])

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    if (Array.isArray(sortedApartments) && sortedApartments.length > 0) {
      return sortedApartments.slice(firstPageIndex, lastPageIndex)
    }
    return []
  }, [currentPage, sortedApartments])

  const requestSort = key => {
    let direction = "ascending"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  const getClassNamesFor = name => {
    return sortConfig.key === name ? sortConfig.direction : undefined
  }

  const apartmentsList = currentTableData.map((apartment, idx) => {
    const cellsOfApartments = Object.keys(fieldsOfApartments).map(field => {
      if (field === "site_number" && apartment[field]) {
        return (
          <td key={field + apartments.site_number}>
            {apartment["site_number_with_index"]}
          </td>
        )
      }
      if (field === "address" && apartment[field]) {
        return (
          <td key={field + apartments.address}>
            {replaceToComa(apartment["address"])}
          </td>
        )
      }
      if (field === "price_total" && apartment[field]) {
        return (
          <td key={field + apartments.site_number}>
            {apartment[field].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}{" "}
            {typeof apartment[field] === "number" && "₽"}
          </td>
        )
      }
      if (field === "price_square" && apartment[field]) {
        return (
          <td key={field + apartments.site_number}>
            {apartment[field].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}{" "}
            {typeof apartment[field] === "number" && "₽"}
          </td>
        )
      }
      if (field === "status") {
        return (
          <td key={field + apartments.site_number}>
            <FreeStatusCellWrapper>
              <StatusBadge status={apartment[field]} />
              {apartment[field]}
            </FreeStatusCellWrapper>
          </td>
        )
      }

      return <td key={field + apartments.site_number}>{apartment[field]}</td>
    })

    return (
      <Row
        onClick={() => {
          showInfo(apartment)
        }}
        key={idx}
        status={apartment.status}
      >
        {cellsOfApartments}
      </Row>
    )
  })

  const headerList = Object.keys(fieldsOfApartments).map(field => {
    return (
      <HeaderCell activeSort={getClassNamesFor(field)} key={field}>
        <SortButton
          aligment={fieldsAligment[field]}
          type="button"
          activeSort={getClassNamesFor(field)}
          onClick={() => requestSort(field)}
        >
          <span
            dangerouslySetInnerHTML={{ __html: fieldsOfApartments[field] }}
          />
          <SortIcon />
        </SortButton>
      </HeaderCell>
    )
  })

  const loadingTable = Array.from(Array(20)).map((x, i) => {
    return (
      <LoadingRows key={i + "loadingTable"}>
        {Object.keys(fieldsOfApartments).map((_x, key) => {
          return (
            <td key={key + "loadingCell"}>
              <LoadingCell />
            </td>
          )
        })}
      </LoadingRows>
    )
  })

  const handlePageClick = ({ selected }) => {
    scrollToTop()
    setPage(selected + 1)
  }

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <tr>{headerList}</tr>
          </thead>
          {loaded ? (
            <tbody>{apartmentsList}</tbody>
          ) : (
            <tbody>{loadingTable}</tbody>
          )}
        </Table>
      </TableWrapper>
      <PaginateContainer>
        {totalPages > 0 && (
          <Paginate
            totalPages={totalPages}
            handlePageClick={data => handlePageClick(data)}
            pageNumber={currentPage - 1}
          />
        )}
      </PaginateContainer>
    </>
  )
}

export default TableApartments

//
// Всего {totalApartments} / Показано {showedApartments} / Количество
// фильтров {amountOfFilters}
