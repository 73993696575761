import React from "react"
import styled from "@emotion/styled"

const ChessmatePlaceHolder = styled.div`
  height: 100vh;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 20px 15px;
  
`

const PlaceHolderWrapper = styled.div`
  display: flex;
`

const PlaceHolder = () => {
  return (
    <PlaceHolderWrapper>
      <ChessmatePlaceHolder />
    </PlaceHolderWrapper>
  )
}

export default PlaceHolder
