import React, { useEffect } from "react"
import Filter from "./filterLayout"
import styled from "@emotion/styled"
import Checkbox from "../dataLayout/common/checkbox"
import RangeInput from "../dataLayout/common/rangeInput"

const WrapperFiltersApartments = styled.div`
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  ${props => props.theme.tablet} {
    margin-bottom: 1rem;
  }
`

const FiltersWrapper = styled.div`
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: white;
  border-radius: 10px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  > div {
    border-bottom: 1px solid #e5e5e5;
    &:last-child {
      border-bottom: none;
    }
  }
`

const FilterHeaderWrapper = styled.div`
  h2 {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: 0em;
  }

  button {
    margin: 0;
    cursor: pointer;
    background-color: white;
    padding: 5px 15px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  padding: 11px 0;
  display: flex;
  justify-content: space-between;
`

const filterList = {
  TYPE: "type",
  STATUS: "status",
  PRICE: "price",
  AREA: "area",
  SQUARE_PRICE: "square_price",
  NUMBER_ROOMS: "number_rooms",
  FLOOR: "floor",
}

// <Filter triggerName="Статус">
//   {Array.from(filtersValues.status.keys()).map(status => {
//     const capitalized = CapitalizeFirstLetter(`${status}`)
//     return (
//       <Checkbox
//         key={capitalized}
//         name={capitalized}
//         active={filterExists(capitalized, filterList.STATUS)}
//         onClick={() =>
//           toggleFilter(capitalized, filterList.STATUS, apartment => {
//             if (typeof apartment.status === "string")
//               return (
//                 apartment.status.toLowerCase() ===
//                 `${status}`.toLowerCase()
//               )
//           })
//         }
//       />
//     )
//   })}
// </Filter>

const Filters = ({ filters, setFilters, filtersValues, clearFilters }) => {
  const filterExists = (name, group) => {
    return filters.find(f => f.name === name && f.group === group) !== undefined
  }

  const addFilter = (name, group, fnc) => {
    setFilters(currentFilters => [...currentFilters, { name, group, fnc }])
  }

  const removeFilter = (name, group) => {
    setFilters(currentFilters =>
      currentFilters.filter(f => !(f.name === name && f.group === group))
    )
  }

  const toggleFilter = (name, group, fnc) => {
    if (filterExists(name, group)) {
      removeFilter(name, group)
    } else {
      addFilter(name, group, fnc)
    }
  }

  function CapitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  return (
    <WrapperFiltersApartments>
      <FilterHeaderWrapper>
        <h2>Фильтры</h2>
        <button onClick={() => clearFilters()}>Сброс</button>
      </FilterHeaderWrapper>
      <FiltersWrapper>
        <Filter triggerName="Тип помещения">
          {Array.from(filtersValues.room_type.keys()).map(type => {
            const capitalized = CapitalizeFirstLetter(`${type}`)
            return (
              <Checkbox
                key={capitalized}
                name={capitalized}
                active={filterExists(capitalized, filterList.TYPE)}
                onClick={() =>
                  toggleFilter(capitalized, filterList.TYPE, apartment => {
                    if (typeof apartment.room_type === "string")
                      return (
                        apartment.room_type.toLowerCase() ===
                        `${type}`.toLowerCase()
                      )
                  })
                }
              />
            )
          })}
        </Filter>

        <Filter triggerName="Стоимость, руб">
          <RangeInput
            name="Стоимость"
            active={filterExists("Стоимость", filterList.PRICE)}
            runFilter={(min, max) => {
              removeFilter("Стоимость", filterList.PRICE)
              addFilter("Стоимость", filterList.PRICE, apartment => {
                return typeof apartment.price_total !== "number"
                  ? min <= parseFloat(apartment.price_total) &&
                      parseFloat(apartment.price_total) <= max
                  : min <= apartment.price_total && apartment.price_total <= max
              })
            }}
          />
        </Filter>
        <Filter triggerName="Площадь, м²">
          <RangeInput
            name="Площадь"
            active={filterExists("Площадь", filterList.AREA)}
            runFilter={(min, max) => {
              removeFilter("Площадь", filterList.AREA)
              addFilter("Площадь", filterList.AREA, apartment => {
                return typeof apartment.total_area_balcony !== "number"
                  ? min <= parseFloat(apartment.total_area_balcony) &&
                      parseFloat(apartment.total_area_balcony) <= max
                  : min <= apartment.total_area_balcony &&
                      apartment.total_area_balcony <= max
              })
            }}
          />
        </Filter>
        <Filter triggerName="Стоимость за м², руб">
          <RangeInput
            name="Стоимость квадрата"
            active={filterExists("Стоимость квадрата", filterList.SQUARE_PRICE)}
            runFilter={(min, max) => {
              removeFilter("Стоимость квадрата", filterList.SQUARE_PRICE)
              addFilter(
                "Стоимость квадрата",
                filterList.SQUARE_PRICE,
                apartment => {
                  return typeof apartment.price_square !== "number"
                    ? min <= parseFloat(apartment.price_square) &&
                        parseFloat(apartment.price_square) <= max
                    : min <= apartment.price_square &&
                        apartment.price_square <= max
                }
              )
            }}
          />
        </Filter>
        <Filter triggerName="Количество комнат">
          {Array.from(filtersValues.room_number.keys())
            .sort()
            .map(number => {
              return (
                <Checkbox
                  key={`${number}`}
                  name={`${number}`}
                  active={filterExists(
                    `Кол-во комнат ${number}`,
                    filterList.NUMBER_ROOMS
                  )}
                  onClick={() =>
                    toggleFilter(
                      `Кол-во комнат ${number}`,
                      filterList.NUMBER_ROOMS,
                      apartment => apartment.room_number === number
                    )
                  }
                />
              )
            })}
        </Filter>
        <Filter triggerName="Этаж">
          <RangeInput
            name="Этаж"
            range={filtersValues.floor}
            active={filterExists("Этаж", filterList.FLOOR)}
            runFilter={(min, max) => {
              removeFilter("Этаж", filterList.FLOOR)
              addFilter(
                "Этаж",
                filterList.FLOOR,
                apartment => min <= apartment.floor && apartment.floor <= max
              )
            }}
          />
        </Filter>
      </FiltersWrapper>
    </WrapperFiltersApartments>
  )
}

export default Filters
