import React from "react"
import styled from "@emotion/styled"
import CloseIcon from "../../../../assets/close.svg"

const InfoWrapper = styled.div`
  background-color: white;
  min-height: 40px;
  padding: 12px 12px 20px 12px;
  display: flex;
  position: relative;
  ${props => props.theme.tablet} {
    flex-direction: column;
    padding: 10px 10px 10px 10px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 14px;
  top: 14px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  height: 18px;
  width: 18px;
  :hover {
    line {
      stroke: black;
    }
  }
`

const CloseButtonComponent = ({ changeInfoShow }) => {
  return (
    <CloseButton
      onClick={() => {
        changeInfoShow(false)
      }}
    >
      <CloseIcon />
    </CloseButton>
  )
}

const InfoApartments = ({ changeInfoShow, children }) => {
  return (
    <InfoWrapper>
      <CloseButtonComponent changeInfoShow={changeInfoShow} />
      {children}
    </InfoWrapper>
  )
}

export default InfoApartments
