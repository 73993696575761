import React, { useState } from "react"
import styled from "@emotion/styled"
import PopUpContainer from "./popupcontainer"
import SchemeImage from "./schemeImage"
import { replaceToComa } from "../../../../helpers"
import { Helmet } from "react-helmet"

const Button = styled.div`
  height: 100%;
  cursor: pointer;
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 40%;
  font-size: 0.8rem;
  padding-right: 30px;
  ${props => props.theme.tablet} {
    padding-right: 5px;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: auto;
    min-height: 200px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.03);
  }
`

const ImageContainer = styled.div``

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 0.4rem;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
`

const RowWrapper = styled.div`
  display: flex;
  ${props => props.theme.tablet} {
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0px 5px 10px 5px;
    border-bottom: 1px solid #e5e5e5;
  }
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  color: #3a4649;
`

const LeftColumnRow = styled.div`
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.spaceBetween ? "space-between" : "400")};
`

const RowKey = styled.div`
  padding: 6px 6px 6px 0;
  flex: 0 0 35%;
  border-top: 1px solid #e5e5e5;
  ${props => props.theme.tablet} {
    flex: 0 0 50%;
    border-top: none;
  }
`

const RowValue = styled.div`
  padding: 6px 6px 6px 8px;
  flex: 1;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  ${props => props.theme.tablet} {
    border: none;
    padding: 0;
    font-weight: bold;
  }
`

const LeftColumnInformation = styled.span`
  display: inline-block;
  margin-right: 15px;
  font-weight: ${props => (props.bold ? "500" : "400")};
`

const Status = styled.span`
  display: inline-block;
  padding: 4px 14px;
  background-color: ${props =>
    props.status === "свободно" ? "#63cba5" : "#909090"};
  color: white;
  border-radius: 10px;
  text-transform: capitalize;
  ${props => props.theme.tablet} {
    margin-right: 35px;
  }
`

const InfoRow = ({ name, value }) => {
  return (
    <RowWrapper>
      <RowKey>{name}</RowKey>
      <RowValue dangerouslySetInnerHTML={{ __html: replaceToComa(value) }} />
    </RowWrapper>
  )
}

const apartmentsInfoMap = {
  developer: "Наименование застройщика",
  developers_inn: "ИНН застройщика",
  address: "Адрес объекта",
  entrance: "Cекция/подъезд",
  site_number_with_index: "Номер на площадке",
  room_type: "Тип помещения",
  room_number: "Количество комнат",
  floor: "Этаж",
  total_area: "Проектная общая площадь, м²",
  total_area_balcony:
    "Проектная общая площадь с учетом балконов и лоджий (с учетом понижающего коэффициента) м²",
  price_square: "Рыночная стоимость 1 м² руб, НДС не облагается",
  price_total:
    "Рыночная стоимость объекта, руб., для жилых помещений - НДС не облагается, для нежилых помещений - с учетом НДС",
}

const InfoApartments = ({ changeInfoShow, selectedApartment: apartment }) => {
  const [open, setGallery] = useState(false)

  function handleClick() {
    setGallery(state => !state)
  }

  const characteristics = Object.keys(apartmentsInfoMap).map(key => {
    if (key === "price_total" || key === "price_square") {
      return (
        <InfoRow
          key={apartmentsInfoMap[key]}
          name={apartmentsInfoMap[key]}
          value={
            apartment[key]
              ? apartment[key].toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : ""
          }
        />
      )
    }
    return (
      <InfoRow
        key={apartmentsInfoMap[key]}
        name={apartmentsInfoMap[key]}
        value={apartment[key]}
      />
    )
  })

  return (
    <PopUpContainer changeInfoShow={changeInfoShow}>
      <Helmet>
        <title>{`${replaceToComa(apartment.address)}, Помещение ${
          apartment.site_number
        }`}</title>
        <meta
          name="description"
          content={`${replaceToComa(apartment.address)}, Помещение ${
            apartment.site_number
          } в продаже Фонд защиты прав граждан -  участников строительства ПК"`}
        />
      </Helmet>
      <LeftColumn>
        <div>
          <LeftColumnRow spaceBetween>
            <LeftColumnInformation bold>
              Помещение №{apartment.site_number_with_index}
            </LeftColumnInformation>
            <Status status={apartment.status}>{apartment.status}</Status>
          </LeftColumnRow>
          <LeftColumnRow>
            <LeftColumnInformation>
              {apartment.room_number} к
            </LeftColumnInformation>
            <LeftColumnInformation>
              {apartment.total_area_balcony} м²
            </LeftColumnInformation>
            <LeftColumnInformation>
              {apartment.floor} этаж
            </LeftColumnInformation>
          </LeftColumnRow>
          <LeftColumnRow>
            <LeftColumnInformation bold>
              {apartment.price_total
                ? apartment.price_total.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })
                : ""}{" "}
              {typeof apartment.price_total === "number" && "₽"}
            </LeftColumnInformation>
            <LeftColumnInformation>
              {apartment.price_square
                ? apartment.price_square.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })
                : ""}{" "}
              {typeof apartment.price_square === "number" && "₽/м²"}
            </LeftColumnInformation>
          </LeftColumnRow>
        </div>
        <ImageWrapper>
          <ImageContainer>
            {apartment.apartment_image && (
              <Button onClick={handleClick}>
                <div>
                  <img
                    src={apartment.apartment_image}
                    alt={apartment.address}
                  />
                </div>
              </Button>
            )}
          </ImageContainer>
        </ImageWrapper>
      </LeftColumn>
      <RightColumn>
        <h3>Характеристики</h3>
        {characteristics}
      </RightColumn>
      {open && (
        <SchemeImage
          onCloseButton={handleClick}
          photo={apartment.apartment_image}
        />
      )}
    </PopUpContainer>
  )
}

export default InfoApartments
