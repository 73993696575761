import React from "react"
import DataLayout from "./dataLayout"
import useApartmentsTags from "../../hooks/use-apartments-tags"
import useSales from "../../hooks/use-sales-page"

const Apartments = () => {
  const tags = useApartmentsTags()
  const salesPage = useSales()

  const filteredTags = tags.filter(tag => {
    if (tag.name !== "реализовано") return true
  })

  console.log(filteredTags)

  return <DataLayout tags={filteredTags} salesPage={salesPage} />
}

export default Apartments
