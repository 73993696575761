import React, { useState, useEffect, useRef } from "react"
import styled from "@emotion/styled"

const RangeWrapper = styled.div`
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
`

const Input = styled.input`
  font-size: 0.8rem;
  font-weight: 400;
  padding: 6px 0 7px;
  width: 100%;
  border: 0;
  &:focus {
    outline: none;
  }
`

const InputContainer = styled.div`
  padding: 5px 8px;
  flex-basis: 45%;
  border: 1px solid gray;
  border-radius: 5px;
`

const RangeInput = ({
  name,
  active,
  runFilter = () => {
    return
  },
}) => {
  const [minMax, setMinMax] = useState({ min: 0, max: Infinity })
  const didMount = useRef(false)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return
    }
    const delayDebounceFn = setTimeout(() => {
      runFilter(minMax.min, minMax.max)
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [minMax])

  useEffect(() => {
    
  }, [active])

  const minMaxValues = value => {
    if (value === Infinity) {
      return
    }
  }

  return (
    <RangeWrapper>
      <InputContainer>
        <Input
          value={minMax.min}
          onChange={e =>
            setMinMax({ ...minMax, min: e.target.value.replace(/\D/g, "") })
          }
        ></Input>
      </InputContainer>
      <InputContainer>
        <Input
          value={minMaxValues(minMax.max)}
          onChange={e =>
            setMinMax({ ...minMax, max: e.target.value.replace(/\D/g, "") })
          }
        ></Input>
      </InputContainer>
    </RangeWrapper>
  )
}

export default RangeInput
