const filtersList = {
  TYPE: "type",
  STATUS: "status",
  PRICE: "price",
  AREA: "area",
  SQUARE_PRICE: "square_price",
  NUMBER_ROOMS: "number_rooms",
  FLOOR: "floor",
}

function isShownByType(apartment, filters) {
  const typeFilters = filters.filter(
    filter => filter.group === filtersList.TYPE
  )
  if (!typeFilters.length) return true
  return typeFilters.some(filter => filter.fnc(apartment))
}

function isShownByStatus(apartment, filters) {
  const statusFilters = filters.filter(
    filter => filter.group === filtersList.STATUS
  )
  if (!statusFilters.length) return true
  return statusFilters.some(filter => filter.fnc(apartment))
}

function isShowByRoomNumber(apartment, filters) {
  const roomNumberFilters = filters.filter(
    filter => filter.group === filtersList.NUMBER_ROOMS
  )
  if (!roomNumberFilters.length) return true
  return roomNumberFilters.some(filter => filter.fnc(apartment))
}

function isShowByFloorNumber(apartment, filters) {
  const floorFilter = filters.filter(
    filter => filter.group === filtersList.FLOOR
  )
  if (!floorFilter.length) return true
  return floorFilter.some(filter => filter.fnc(apartment))
}

function isShowByTotalArea(apartment, filters) {
  const totalAreaFilter = filters.filter(
    filter => filter.group === filtersList.AREA
  )
  if (!totalAreaFilter.length) return true
  return totalAreaFilter.some(filter => filter.fnc(apartment))
}

function isShowByPrice(apartment, filters) {
  const priceFilter = filters.filter(
    filter => filter.group === filtersList.PRICE
  )
  if (!priceFilter.length) return true
  return priceFilter.some(filter => filter.fnc(apartment))
}

function isShowBySquarePrice(apartment, filters) {
  const squarePriceFilter = filters.filter(
    filter => filter.group === filtersList.SQUARE_PRICE
  )
  if (!squarePriceFilter.length) return true
  return squarePriceFilter.some(filter => filter.fnc(apartment))
}

export function applyFilters(apartments, filters) {
  return apartments.filter(apartment => {
    const showByType = isShownByType(apartment, filters)
    const showByStatus = isShownByStatus(apartment, filters)
    const showByRoomNumbers = isShowByRoomNumber(apartment, filters)
    const showByFloorNumber = isShowByFloorNumber(apartment, filters)
    const showByTotalArea = isShowByTotalArea(apartment, filters)
    const showByPrice = isShowByPrice(apartment, filters)
    const showBySquarePrice = isShowBySquarePrice(apartment, filters)
    return (
      showByType &&
      showByStatus &&
      showByRoomNumbers &&
      showByFloorNumber &&
      showByTotalArea &&
      showByPrice &&
      showBySquarePrice
    )
  })
}
