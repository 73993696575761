import React, { useMemo, useState } from "react"
import styled from "@emotion/styled"
import Cell from "./cell"
import Row from "./row"
import StatusBadge from "../common/statusBadge"
import PlaceHolder from "./chessPlaceHolder"
import {
  parseApartmentsByEntrace,
  parseApartmentsByFloor,
  fillEmptyFloors,
} from "../../../../helpers"

const ChessWrapper = styled.div`
  background-color: white;
  padding: 14px 7px;
  width: 100%;
  overflow: auto;
`

const ChessRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-right: 18px;
  align-items: center;
  background-color: ${props => {
    if (props.hoveredRow) return "rgba(0, 0, 0, 0.05)"
    return "transparent"
  }};
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

const Status = styled.span`
  display: inline-block;
  margin: 0 7px;
  font-size: 0.8rem;
`

const EntraceRow = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  &:before {
    content: '${props => (props.firstRow ? `${props.entrance}` : "")}';
    position: absolute;
    font-size: 0.8rem;
    top: -1.1rem;
    left: 0.8rem;
    display: block;
    line-height: 1;
  }

    &:after {
    content: '${props => (props.lastRow ? `${props.entrance}` : "")}';
    position: absolute;
    font-size: 0.8rem;
    bottom: -1.2rem;
    left: 0.8rem;
    display: block;
    line-height: 1;
  }
`

const FloorNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  min-width: ${props => {
    if (props.end) return "150px"
    return "22px"
  }};
  height: 22px;
  margin: 5px;
  color: #3a4649;
`

const BadgeWrapper = styled.div`
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 22px;
  margin-left: 8px;
`

const ChessContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 2rem 0 1.5rem 0;
  justify-content: space-between;
`

const EntranceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const FloorInfowRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
`

const Chess = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

const ChessApartments = ({
  loaded,
  apartments,
  showInfo,
  filteredApartments,
  showFloorInfo,
}) => {
  const [hoveredFloor, setHoverFloor] = useState(0)

  function handleHoverOverRow(currentFloor) {
    setHoverFloor(currentFloor)
  }

  function checkIfFiltered(obj) {
    return filteredApartments.some(elem => elem === obj)
  }

  function sortBySiteNumber(apartments) {
    apartments.sort((a, b) => {
      if (a.site_number < b.site_number) {
        return -1
      }
      if (a.site_number > b.site_number) {
        return 1
      }
      return 0
    })
    return apartments
  }

  const sortedApartments = useMemo(() => sortBySiteNumber(apartments), [
    apartments,
  ])

  const apartmentsbyEntranceAndFloor = parseApartmentsByEntrace(
    sortedApartments
  ).reduce((acc, entrance) => {
    const apartmentsByFloor = parseApartmentsByFloor(entrance)
    const sortedKeys = Object.keys(apartmentsByFloor)
      .sort((a, b) => {
        return parseInt(a) - parseInt(b)
      })
      .reduce((accumulator, key) => {
        return [...accumulator, apartmentsByFloor[key]]
      }, [])
    return [...acc, sortedKeys]
  }, [])

  const filledApartments = fillEmptyFloors(apartmentsbyEntranceAndFloor)

  const chessBoard = filledApartments.map((val, indx) => {
    return (
      <EntranceWrapper key={indx}>
        {val.reverse().map((row, indxOfRow) => {
          const entraceNumber = row[0]?.entrance
          const totalRows = val.length
          const floorNumber =
            typeof row[0]?.floor === "undefined"
              ? val.length - indxOfRow
              : row[0]?.floor

          if (floorNumber === 0) {
            return
          }
          return (
            <ChessRow
              key={indxOfRow}
              onMouseEnter={() => {
                handleHoverOverRow(floorNumber)
              }}
              onMouseLeave={() => {
                handleHoverOverRow(0)
              }}
              hoveredRow={floorNumber === hoveredFloor}
            >
              {row[0]?.entrance === 1 && (
                <FloorNumber>{floorNumber}</FloorNumber>
              )}
              <EntraceRow
                firstRow={indxOfRow === 0}
                lastRow={indxOfRow + 1 === totalRows}
                entrance={entraceNumber}
              >
                {row.map((cell, index) => (
                  <Cell
                    active={checkIfFiltered(cell)}
                    showInfo={showInfo}
                    moveBadge={indxOfRow === 0}
                    cell={cell}
                    key={index}
                  />
                ))}
              </EntraceRow>
            </ChessRow>
          )
        })}
      </EntranceWrapper>
    )
  })

  const floorInfo = filledApartments.map((val, indx) => {
    if (indx === filledApartments.length - 1) {
      return (
        <React.Fragment key={indx}>
          {val.map((row, indexRow) => {
            const floorNumber =
              typeof row[0]?.floor === "undefined"
                ? val.length - indexRow
                : row[0]?.floor
            console.log(indexRow)
            if (floorNumber === 0) {
              return
            }
            return (
              <Row
                hoveredRow={floorNumber === hoveredFloor}
                key={indexRow}
                floorNumber={floorNumber}
                floor={apartments}
                showFloorInfo={showFloorInfo}
                onMouseEnter={() => {
                  handleHoverOverRow(floorNumber)
                }}
                onMouseLeave={() => {
                  handleHoverOverRow(0)
                }}
              ></Row>
            )
          })}
        </React.Fragment>
      )
    }
    return
  })

  return (
    <ChessWrapper>
      <BadgeWrapper>
        <StatusContainer>
          <StatusBadge status="свободно" />
          <Status>Свободно</Status>
        </StatusContainer>
        <StatusContainer>
          <StatusBadge status="реализовано" />
          <Status>Реализовано</Status>
        </StatusContainer>
      </BadgeWrapper>
      {loaded ? (
        <ChessContainer>
          <Chess>{chessBoard}</Chess>
          <FloorInfowRow>{floorInfo}</FloorInfowRow>
        </ChessContainer>
      ) : (
        <PlaceHolder />
      )}
    </ChessWrapper>
  )
}

export default ChessApartments
