import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import StatusBadge from "../common/statusBadge"
import Car from "../../../../assets/car.svg"

const CarIcon = styled(Car)`
  height: 100%;
  fill: white;
`

const ChessCell = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  text-align: center;
  color: white;
  height: 22px;
  width: 22px;
  margin: 2px;
  background-color: ${props => {
    if (props.status.toLowerCase() === "реализовано" && !props.active)
      return "rgba(144, 144, 144, 0.5)"
    if (props.status.toLowerCase() === "свободно" && !props.active)
      return "rgba(99, 203, 165, 0.5)"
    if (props.status.toLowerCase() === "реализовано")
      return "rgba(144, 144, 144, 1)"
    if (props.status.toLowerCase() === "свободно")
      return "rgba(99, 203, 165, 1)"
  }};
  transition: background-color 200ms linear;
`

const EmptyCell = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  text-align: center;
  height: 22px;
  width: 22px;
  margin: 2px;
`

const EmptyCellContainer = styled.div`
  margin: 3px 4px;
  border: 2px solid transparent;
  color: transparent;
`

const Info = styled.div`
  text-align: left;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 7px 15px 3px 10px;
  position: absolute;
  color: black;
  top: -95px;
  left: -10px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  border-radius: 5px;
  min-width: 160px;
  min-height: 70px;
  font-size: 0.7rem;
  ::before {
    left: 2px;
    bottom: -24px;
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 16V1H3L17.5 16Z' fill='%23d9d9d9' stroke='%23d9d9d9' stroke-width='2'/%3E%3C/svg%3E%0A");
  }
`

const CellContainer = styled.div`
  cursor: ${props => {
    if (props.status.toLowerCase() === "реализовано") return "cursor"
    if (props.status.toLowerCase() === "свободно") return "pointer"
  }};
  user-select: none;
  margin: 3px 4px;
  :hover {
    > div {
      background-color: ${props => {
        if (props.status.toLowerCase() === "реализовано")
          return "rgba(144, 144, 144, 1)"
        if (props.status.toLowerCase() === "свободно")
          return "rgba(99, 203, 165, 1)"
      }};
    }
  }
  border: ${props => {
    if (props.hover) return "2px solid #123963"
    return "2px solid transparent"
  }};
`

const InfoTopLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  align-items: center;
  div {
    display: flex;
    align-items: center;
  }
  span {
    line-height: 1;
  }
`

const SiteNumberContainer = styled.span`
  font-size: 0.8rem;
`

const SiteNumberWrapper = styled.div`
  margin-left: 10px;
`

const StatusContainer = styled.div`
  font-weight: 500;
  margin: 4px 0 2px 0;
  font-size: 0.8rem;
  line-height: 1rem;
  text-transform: capitalize;
`

const Cell = ({ cell, showInfo, active }) => {
  const [showShortInfo, setIsShow] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)

  useEffect(() => {
    if (mouseOver === false) {
      setIsShow(mouseOver)
      return
    }
    const delayDebounceFn = setTimeout(() => {
      setIsShow(mouseOver)
    }, 300)
    return () => clearTimeout(delayDebounceFn)
  }, [mouseOver])

  if (cell.filler) {
    return (
      <EmptyCellContainer>
        <EmptyCell></EmptyCell>
      </EmptyCellContainer>
    )
  }
  return (
    <CellContainer
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      status={cell.status}
      hover={showShortInfo}
      onClick={() => {
        if (cell.status.toLowerCase() === "реализовано") return
        showInfo(cell)
      }}
    >
      <ChessCell status={cell.status} active={active}>
        {cell.room_number}
        {cell.room_type.toLowerCase() === "машиноместо" ? <CarIcon /> : null}
        {showShortInfo ? (
          <Info>
            <InfoTopLine>
              <div>
                <StatusBadge status={cell.status}>
                  <span>{cell.room_number}</span>
                </StatusBadge>
                <span>{cell.room_type}</span>
              </div>
              <SiteNumberWrapper>
                <SiteNumberContainer>
                  №{cell.site_number_with_index}
                </SiteNumberContainer>
              </SiteNumberWrapper>
            </InfoTopLine>
            <StatusContainer>{cell.status}</StatusContainer>
            <div>
              {cell.total_area_balcony} м<sup>2</sup>
            </div>
          </Info>
        ) : null}
      </ChessCell>
    </CellContainer>
  )
}

export default Cell
