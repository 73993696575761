import React from "react"
import Layout from "../components/layout"
import Row from "../components/layout/row"
import Apartments from "../components/apartments"
import { Helmet } from "react-helmet"

const ApartmentsPage = props => {
  return (
    <Layout>
      <Helmet>
        <title>Продажа квартир в Пермском крае</title>
        <meta name="description" content="Продажа квартир в Пермском крае" />
      </Helmet>
      <Row margin="2rem auto">
        <Apartments />
      </Row>
    </Layout>
  )
}

export default ApartmentsPage
