import React, { useRef, useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import Row from "../../../layout/row"
import CloseButton from "../../../common/closebutton"
import { Swiper, SwiperSlide } from "swiper/react"

const RowSt = styled(Row)`
  position: relative;
  ${props => props.theme.tablet} {
    max-width: 100vw;
    width: 1330px;
  }
`

const GalleryWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  color: ${props => props.theme.colors.footerFont};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .swiper-pagination {
    bottom: 3.2rem;
  }
  .swiper-pagination-bullet {
    background: white;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
    ${props => props.theme.tablet} {
      top: 40%;
    }
  }
`

const SwipperWrapper = styled.div`
  position: relative;
  margin: 0 auto;
`

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Image = styled.img`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  max-height: 70vh;
  flex-shrink: 2;
`

const ImageWrapper = styled.div`
  background: black;
  width: 100%;
`

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SchemeImage = props => {
  const wrapperRef = useRef(null)

  const slide = (
    <SwiperSlide>
      <Slide>
        <ImageWrapper>
          <Image src={props.photo} />
        </ImageWrapper>
      </Slide>
    </SwiperSlide>
  )

  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      props.onCloseButton()
    }
  }, [])

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.onCloseButton()
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      document.addEventListener("keydown", escFunction, false)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
        document.removeEventListener("keydown", escFunction, false)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  return (
    <GalleryWrapper>
      <RowSt>
        <div ref={wrapperRef}>
          <SwipperWrapper>
            <Swiper>{slide}</Swiper>
            <CloseButtonWrapper>
              <CloseButton onClick={() => props.onCloseButton()}>
                &times;
              </CloseButton>
            </CloseButtonWrapper>
          </SwipperWrapper>
        </div>
      </RowSt>
    </GalleryWrapper>
  )
}

export default SchemeImage
