import React, { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { addQueryParam, scrollToTop, replaceToComa } from "../../../helpers"

const SelectionWrapper = styled.div`
  margin-bottom: 13px;
  width: 100%;
  position: relative;
`

const SelectionButton = styled.button`
  box-sizing: border-box;
  min-height: 1.8rem;
  width: 100%;
  padding: 8px 8px;
  position: relative;
  text-align: left;
  background: #ffffff;
  border: ${props =>
    props.isOptionsOpen ? "1px solid #123963" : "1px solid #e5e5e5"};
  border-radius: 10px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 17px;

  color: #3a4649;

  cursor: pointer;
  :after {
    position: absolute;
    right: 13px;
    top: 7px;
    transform: ${props => (props.isOptionsOpen ? "rotate(180deg)" : "none")};
    transition-duration: 0.3s;
    transition-property: transform;
    content: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.881836 0.882355L6.67131 5.88235L11.8818 0.882355' stroke='%23909090' stroke-width='2'/%3E%3C/svg%3E%0A");
  }
`

const OptionsList = styled.ul`
  z-index: 10;
  top: 26px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
  right: 0;
  border-left: ${props =>
    props.isOptionsOpen ? "1px solid #123963" : "1px solid #e5e5e5"};
  border-right: ${props =>
    props.isOptionsOpen ? "1px solid #123963" : "1px solid #e5e5e5"};
  border-bottom: ${props =>
    props.isOptionsOpen ? "1px solid #123963" : "1px solid #e5e5e5"};
  box-sizing: border-box;
  position: absolute;
  background-color: #ffffff;
  display: ${props => (props.isOptionsOpen ? "block" : "none")};
  list-style: none;
  padding: 15px 0 7px 0;
  width: 100%;
  font-size: 0.8rem;
  li {
    cursor: pointer;
    padding: 8px 8px;
    :hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
`

const TableApartmentsSelection = ({
  tags,
  selectTag,
  selectedTag,
  changeInfoShow,
  clearFilters,
  clearScrollValue,
  closeFloorInfo,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const wrapperRef = useRef(null)

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen)
  }

  useEffect(() => {
    const nameOfTag = tags.find(tag => tag.tagId == selectedTag)?.name
    nameOfTag === "свободно"
      ? setSelectedOption("Все свободные помещения")
      : setSelectedOption(nameOfTag)
  }, [tags, selectedTag])

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          isOptionsOpen
        ) {
          toggleOptions()
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, isOptionsOpen])
  }

  useOutsideAlerter(wrapperRef)

  const setSelectedThenCloseDropdown = (tagId, tagName) => {
    clearScrollValue()
    scrollToTop()
    changeInfoShow(false)
    addQueryParam("tag", tagId)
    tagName === "свободно"
      ? setSelectedOption("Все свободные помещения")
      : setSelectedOption(tagName)
    selectTag(tagId)
    setIsOptionsOpen(false)
    closeFloorInfo(false)
  }

  const newTagsList = tags.map((tag, index) => {
    if (tag.name === "свободно") {
      return (
        <li
          key={tag.tagId}
          id={tag.tagId}
          role="option"
          onClick={() => {
            setSelectedThenCloseDropdown(tag.tagId, tag.name)
          }}
        >
          Все свободные помещения
        </li>
      )
    }

    return (
      <li
        key={tag.tagId}
        id={tag.tagId}
        role="option"
        onClick={() => {
          setSelectedThenCloseDropdown(tag.tagId, tag.name)
        }}
        dangerouslySetInnerHTML={{ __html: replaceToComa(tag.name) }}
      />
    )
  })

  return (
    <SelectionWrapper ref={wrapperRef}>
      <SelectionButton
        type="button"
        aria-haspopup="listbox"
        isOptionsOpen={isOptionsOpen}
        onClick={() => toggleOptions()}
        dangerouslySetInnerHTML={{ __html: replaceToComa(selectedOption) }}
      />
      <OptionsList isOptionsOpen={isOptionsOpen}>{newTagsList}</OptionsList>
    </SelectionWrapper>
  )
}

export default TableApartmentsSelection
